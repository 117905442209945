

































import Vue from 'vue';
import Component from 'vue-class-component';
import GenericContent from '@/components/content/GenericContent.vue';
import gql from 'graphql-tag';

@Component({
  components: {
    GenericContent,
  },
})
export default class About extends Vue {
  public isLoadingDownloads: boolean = false;

  public listOfPDFs: any[] = [];

  mounted() {
    this.loadPDFs();
  }

  private loadPDFs() {
    this.$emit('DataLoaded', false);
    this.isLoadingDownloads = true;
    this.$apollo.query({
      query: gql`query {
        entry(section: "Downloads") {
          ... on Downloads_Downloads_Entry {
            pdf {
              id
              title
              url
            }
          }
        }
      }
    `,
    }).then((data) => {
      this.isLoadingDownloads = false;
      if (data.data.entry.pdf != null) {
        this.listOfPDFs = data.data.entry.pdf || {};
      }
    });
  }
}
